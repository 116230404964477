import React, { Component, Fragment } from 'react';
import ReactImageFallback from "react-image-fallback";
import { NavLink } from 'react-router-dom';
import { AJAX_REQUEST, CURRENCY_FORMAT, GET_STORAGE } from "../../../Constants/AppConstants";
import history from '../../../history';
import daterangepicker from 'daterangepicker';
import productImage from '../../../Assets/images/customer_images/order_product_img.svg'
import $ from 'jquery';
import RelatedOrders from './RelatedOrders';
import moment from "moment";
import AlertWrapperSuccess from '../../Common/AlertWrapperSuccess';
import AlertWrepper from "../../Common/AlertWrapper";
import { Row, Col } from 'react-bootstrap';
import ProgressBar from 'react-bootstrap/ProgressBar';
import EmailIcon from '../../../Assets/images/customer_images/email_icon.svg'
import PhoneIcon from '../../../Assets/images/customer_images/phone_icon.svg'
import Parser from 'html-react-parser';


class RelatedSubscription extends Component {
    constructor(props) {
        super(props)
        // Check Tax
        let settings = '';
        if (GET_STORAGE('settings')) {
            settings = JSON.parse(GET_STORAGE('settings'));
        }

        const url = new URL(window.location.href);
        const is_subscription_orders_page = url.searchParams.get("is_subscription_orders_page");

        this.state = {
            taxStatus: settings ? settings.tax_status : 0,
            status: '',
            start_date: '',
            last_order_date: '',
            next_payment_date: '',
            end_date: '',
            subscription: [],
            items: [],
            billing_address: [],
            shipping_address: [],
            related_order: [],
            subtotal: 0,
            tax_amount: 0,
            display_subtotal: 0,
            shipping_charge: 0,
            display_shipping_charge: 0,
            shipping_method: '',
            payment_method: '',
            total: 0,
            display_total: 0,
            subscription_type: '',
            duration: '',
            billing_interval: '',
            subscription_id: parseInt(this.props.match.params.subscription_id),
            error_meg: '',
            loading: true,
            is_subscription_orders_page: is_subscription_orders_page,
            subs_item_add_success_msg: '',
            success_alert_wrapper_show: false,
            is_form_valid: true,
            tenDaysDifference: true,
        }

        this.detailsLoaded = false;
        this.urlDataProcessed = false;
        document.title = "Related Subscription -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView() // Scroll to Top
        this.getDetails();
        this.timeOut(5000);
    }
    componentDidUpdate() {
        if(!this.urlDataProcessed){
            this.processUrlData(); 
        } 
    }
    
    processUrlData() {
        const urlParams = new URLSearchParams(window.location.search);
        const isApproved = urlParams.get('is_approved');
        const type = urlParams.get('type');
        const subscriptionTaxInfo = urlParams.get('subscription_tax_info');
        const subscription_item_id = urlParams.get('subscription_item_id');
        const subscriptionItems = [];
        for (let i = 0; urlParams.has(`subscription_items[${i}][product_id]`); i++) {
            const product_id = urlParams.get(`subscription_items[${i}][product_id]`);
            const variation_id = urlParams.get(`subscription_items[${i}][variation_id]`);
            subscriptionItems.push({ product_id, variation_id });
        }
       
        if(isApproved == 1 && type == 'AddItem'){
            this.addItem(isApproved, subscriptionItems, subscriptionTaxInfo)
        }else if(isApproved == 1 && type == 'removeItem'){
            this.removeItem(isApproved, subscription_item_id, subscriptionTaxInfo);
        }
        this.urlDataProcessed = true;
    }

    addItem = (isApproved, subscriptionItems, subscriptionTaxInfo)=> {
        let data = {
            is_approved: isApproved,
            subscription_id: this.state.subscription_id,
            subscription_items: subscriptionItems,
            subscription_tax_info: subscriptionTaxInfo,
        }
        this.setState({ isLoading: true, saving: true, });
        AJAX_REQUEST("POST", "subscription/addItem", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    error_meg: results.response.message,
                    loading: false,
                    success_alert_wrapper_show: true,
                    is_subscription_orders_page: '',
                });
                history.push("/my-account/related-subscription/" + this.state.subscription_id);
            } else {
                this.setState({
                    error_meg: results.response.message,
                    loading: false,
                    success_alert_wrapper_show: false,
                    saving: false,
                });
            }
        });
    }
    
    removeItem = (isApproved, subscription_item_id)=> {
        let data = {
            is_approved: isApproved,
            subscription_id: this.state.subscription_id,
            subscription_item_id: subscription_item_id
        }
        AJAX_REQUEST("POST", "subscription/removedItem", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                this.setState({
                    error_meg: results.response.message,
                    loading: false,
                    success_alert_wrapper_show: true,
                    is_subscription_orders_page: '',
                });
                history.push("/my-account/related-subscription/" + this.state.subscription_id);
                
            } else {
                this.setState({
                    error_message: results.response.message,
                    loading: false,
                    success_alert_wrapper_show: false,
                });
            }
        });
    }
    getDetails = () => {
        let gd_data = {};
        if (parseInt(this.state.is_subscription_orders_page) === 1) {
            gd_data = {
                subscription_id: this.state.subscription_id,
                is_subscription_orders_page: 1
            }
        } else {
            gd_data = {
                subscription_id: this.state.subscription_id
            }
        }
        AJAX_REQUEST("POST", "subscription/getDetails", gd_data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                let data = results.response.data;
                this.setState({
                    status: data.status,
                    start_date: data.start_date,
                    last_order_date: data.last_order_date,
                    next_payment_date: data.next_payment_date,
                    end_date: data.end_date,
                    subscription: data.subscription,
                    items: data.subscription.items,
                    subtotal: data.subscription.subtotal,
                    tax_amount: data.subscription.tax_amount,
                    display_subtotal: data.subscription.display_subtotal,
                    shipping_charge: data.subscription.shipping_charge,
                    display_shipping_charge: data.subscription.display_shipping_charge,
                    shipping_method: data.subscription.shipping_method,
                    payment_method: data.subscription.payment_method,
                    total: data.subscription.total,
                    display_total: data.subscription.display_total,
                    subscription_type: data.subscription.subscription_type,
                    duration: data.subscription.duration,
                    billing_interval: data.subscription.billing_interval,
                    billing_address: data.billing_address,
                    shipping_address: data.shipping_address,
                    related_order: data.related_order,
                    subscription_id: this.props.match.params.subscription_id,
                    loading: false,
                    is_subscription_orders_page: this.state.is_subscription_orders_page,
                    subs_item_add_success_msg: data.success_message ? data.success_message : '',
                    tenDaysDifference: data.tenDaysDifference
                });
            } else if (parseInt(results.response.code) === 4000 || parseInt(results.response.code) === 4004) {
                history.push('/my-account/subscriptions');
            } else {
                this.setState({
                    error_meg: results.response.message,
                    loading: false,
                });
            }
        });
    }
    timeOut = (timedata) => {
        setTimeout(function () {
            this.setState({
                is_subscription_orders_page: ''
            });
        }.bind(this), timedata);
    }

    removeSubscriptionItem = (itemId) => {
        if (window.confirm('Are you sure you want remove this item from your subscription?')) {
            document.querySelector("body").scrollIntoView() // Scroll to Top
            let data = {
                subscription_id: this.state.subscription_id,
                subscription_item_id: itemId
            }
            this.setState({
                error_meg: '',
                success_alert_wrapper_show: false
            });
            AJAX_REQUEST("POST", "subscription/removedItem", data).then(results => {
                if (parseInt(results.response.code) === 1000) {
                    if(this.state.payment_method == 'paypal') {
                        console.log(results.response.checkoutUrl, 'results.response.checkoutUrl');
                        window.location.href = results.response.checkoutUrl;
                    }
                    this.setState({
                        error_meg: results.response.message,
                        loading: false,
                        success_alert_wrapper_show: true,
                        is_subscription_orders_page: '',
                    });
                    this.getDetails();
                    setTimeout(function () {
                        this.setState({
                            success_alert_wrapper_show: false
                        });
                    }.bind(this), 3000);
                } else {
                    this.setState({
                        error_message: results.response.message,
                        loading: false,
                        success_alert_wrapper_show: false,
                    });
                }
            });
        }
    }

    openChangeDate = (e) => {
        $('.subscription_end_date_edit').hide();
        $('.subscription_end_date_edit_calender').show();

        $('.datepicker').daterangepicker({
            autoUpdateInput: false,
            singleDatePicker: true,
            showDropdowns: true,
            startDate: moment(this.state.next_payment_date, "MMMM DD, YYYY").format('YYYY-MM-DD'),
            minDate: this.state.payment_method == 'paypal' ? moment().add(10, "days") : moment(),
            maxDate: moment().add(60, 'days').format('YYYY-MM-DD'),
            locale: {
                format: 'YYYY-MM-DD'
            }
        });

        $('.datepicker').on('apply.daterangepicker', function (ev, picker) {
            $(this).val(picker.startDate.format('YYYY-MM-DD'));
        });

        $('.datepicker').on('cancel.daterangepicker', function (ev, picker) {
            $(this).val(moment(this.state.next_payment_date, "MMMM DD, YYYY").format('YYYY-MM-DD'));
        });
    }

    closeChangeDate = (e) => {
        $('.subscription_end_date_edit').show();
        $('.subscription_end_date_edit_calender').hide();
        $('.datepicker').val(moment(this.state.next_payment_date, "MMMM DD, YYYY").format('YYYY-MM-DD'));
    }

    saveChange = (e) => {
        e.preventDefault();
        $('.subscription_end_date_edit').show();
        $('.subscription_end_date_edit_calender').hide();

        const next_payment_date = $('#the_date').val();
        const data = { next_payment_date: next_payment_date, subscription_id: this.state.subscription_id }
        this.setState({
            error_meg: '',
            is_form_valid: true,
            success_alert_wrapper_show: false
        });

        AJAX_REQUEST("POST", "subscription/changePaymentDate", data).then(results => {
            const response = results.response;
            if (parseInt(results.response.code) === 1000) {
                const new_dated = results.response.data.next_payment_date;
                this.setState({
                    error_meg: results.response.message,
                    loading: false,
                    success_alert_wrapper_show: true
                });
                setTimeout(function () {
                    this.setState({
                        success_alert_wrapper_show: false
                    });
                }.bind(this), 3000);
                this.setState({
                    next_payment_date: new_dated
                })
                $('#show_new_date').text(new_dated);
                this.getDetails();
            } else {
                console.log('not success');
                this.setState({
                    error_meg: results.response.message,
                    loading: false,
                    is_form_valid: false,
                    success_alert_wrapper_show: false,
                });
            }
        });
    }

    changeHandler = (e) => {
        this.setState({
            [e.target.name]: e.target.value
        })
    }

    onHoldSubscription = (e) => {
        if (window.confirm('Are you sure you want hold this subscription?')) {
            document.querySelector("body").scrollIntoView() // Scroll to Top
            let data = {
                subscription_id: this.state.subscription_id,
            }
            this.setState({
                error_meg: '',
                success_alert_wrapper_show: false
            });
            AJAX_REQUEST("POST", "subscription/onhold", data).then(results => {
                if (parseInt(results.response.code) === 1000) {
                    this.setState({
                        error_meg: results.response.message,
                        loading: false,
                        success_alert_wrapper_show: true,
                        is_subscription_orders_page: '',
                    });
                    this.getDetails();
                    setTimeout(function () {
                        this.setState({
                            success_alert_wrapper_show: false
                        });
                    }.bind(this), 3000);
                } else {
                    this.setState({
                        error_meg: results.response.message,
                        loading: false,
                        success_alert_wrapper_show: false
                    });
                }
            });
        }
    }

    activateSubscription = (e) => {
        if (window.confirm('Are you sure you want activate this subscription?')) {
            document.querySelector("body").scrollIntoView() // Scroll to Top
            let data = {
                subscription_id: this.state.subscription_id,
            }
            this.setState({
                error_meg: '',
                success_alert_wrapper_show: false
            });
            AJAX_REQUEST("POST", "subscription/activate", data).then(results => {
                if (parseInt(results.response.code) === 1000) {
                    this.setState({
                        error_meg: results.response.message,
                        loading: false,
                        success_alert_wrapper_show: true,
                        is_subscription_orders_page: '',
                    });
                    this.getDetails();
                    setTimeout(function () {
                        this.setState({
                            success_alert_wrapper_show: false
                        });
                    }.bind(this), 3000);
                } else {
                    this.setState({
                        error_meg: results.response.message,
                        loading: false,
                        success_alert_wrapper_show: false
                    });
                }
            });
        }
    }

    changeAddress = (e) => {
        e.preventDefault();
        history.push(`/my-account/edit-address/shipping/${this.state.subscription_id}`);
    }

    render() {
        const user = this.props.user;
        return (
            <Fragment>
                {
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <div className="MyAccount-content">
                            {
                                parseInt(this.state.is_subscription_orders_page) === 1 ?
                                    <AlertWrapperSuccess errors_data={this.state.subs_item_add_success_msg} success_alert_wrapper_show={true} />
                                    :
                                    null
                            }
                            {
                                (this.state.error_message) ?
                                <AlertWrepper errors_data={Parser(this.state.error_message)} success_alert_wrapper_show={this.state.success_alert_wrapper_show} />
                                :
                                null
                            }
                            {
                                (this.state.payment_method === 'paypal' && !this.state.tenDaysDifference) ? (
                                    <div className="alert-wrapper">
                                        <ul className="alert-error">
                                            <li>
                                                <strong></strong>
                                                <span style={{ fontSize: '15px', lineHeight: '17px' }}>
                                                    Payment for the subscription is in progress. Contact support with any questions or concerns.
                                                    {' '}<a style={{ float: 'none' }} href="https://prestigelabs.com/contact"> prestigelabs.com/contact</a>
                                                </span>
                                            </li>
                                        </ul>
                                    </div>
                                ) : null
                            }

                            <AlertWrepper isFormValid={this.state.is_form_valid} errors_data={this.state.error_meg} />
                            <AlertWrapperSuccess errors_data={this.state.error_meg} success_alert_wrapper_show={this.state.success_alert_wrapper_show} />



                            <div className="sub_current_div">
                                <Row className='align-items-center mb_43 justify-content-between'>
                                    <Col md={6}>
                                        <h3 className="font_16 m-0 text_black fw_ar_reg let_spa_2">Order Type : Monthly</h3>
                                    </Col>
                                    <Col md={4} className="d-flex justify-content-end align-items-center">
                                        {/* {
                                            this.state.items.length <= 0 ? null :
                                                this.state.items.map(function (item, key) {
                                                    if ((this.state.status === "Cancelled") || (this.state.status === "Failed")) {
                                                        return ( */}
                                        <h3 className="font_16 m-0 text_black fw_ar_reg let_spa_2 text-center mr_36">{CURRENCY_FORMAT(this.state.total)}</h3>
                                        {/* )
                                                    } else {
                                                        return (
                                                            <h3 className="font_16 m-0 text_black fw_ar_reg let_spa_2 text-center mr_36">{CURRENCY_FORMAT(this.state.total)}</h3>
                                                        )
                                                    }

                                                }.bind(this))
                                        } */}
                                        <span className="status_active d-flex align-items-center font_16 text_white let_spa_2 fw_ar_reg">{this.state.status}</span>
                                    </Col>
                                </Row>

                                <div className="start_end_div mb_29">
                                    <div className="d-flex mb_7 justify-content-between">
                                        <h4 className="font_14 m-0 text_black let_spa_2 fw_ar_reg">Start Date : {this.state.start_date}</h4>
                                        <h4 className="font_14 m-0 text_black let_spa_2 fw_ar_reg">End Date : {this.state.end_date}</h4>
                                    </div>
                                </div>

                                <div className="d-flex justify-content-between">
                                    <div className="w-22">
                                        <h3 className="font_16 m-0 text_black let_spa_2 fw_ar_bold">Included in Bundle:</h3>
                                        {
                                            this.state.items.length <= 0 ? <tr><td className="text-center" colSpan="3">No Item Found!</td></tr> :
                                                this.state.items.map(function (item, key) {
                                                    if ((this.state.status === "Cancelled") || (this.state.status === "Failed")) {
                                                        return (
                                                            <p className="font_16 m-0 text_black let_spa_2 fw_ar_reg">{item.hasOwnProperty('name') ? item.name : ''}</p>
                                                        )
                                                    } else {
                                                        return (
                                                            <p className="font_16 m-0 text_black let_spa_2 fw_ar_reg">{item.hasOwnProperty('name') ? item.name : ''}</p>
                                                        )
                                                    }

                                                }.bind(this))
                                        }

                                    </div>
                                    <div className="">
                                        <p className="font_16 m-0 text_black let_spa_2 fw_ar_bold text-right">{this.state.next_payment_date}</p>
                                        <p className="font_16 m-0 text_black let_spa_2 fw_ar_reg text-right">Your Next Renewal</p>
                                        <h3 className="font_16 m-0 text_black fw_ar_reg let_spa_2 payment_method">Payment Method : { 
                                            this.state.payment_method == 'paypal' ? 'PayPal' : 
                                            this.state.payment_method == 'authorize' ? 'Authorize.Net' : 
                                            this.state.payment_method
                                        }
                                        </h3>
                                    </div>
                                </div>
                                <div className="mt_19 d-flex align-items-center justify-content-between">
                                <div className='d-flex flex-wrap active_cancel_mob_sub'>
                                    {(this.state.status == 'On-hold') ?
                                        <NavLink className="buy_now fw_ar_reg font_16 text_white mr_36" to={`/my-account/subscription-activation/${this.state.subscription_id}`}>Activate Subscription</NavLink>
                                        : ''
                                    }
                                    <NavLink className="buy_now fw_ar_reg font_16 text_white mr_36" to={`/my-account/subscription-cancellation/${this.state.subscription_id}`}>Cancel Subscription</NavLink>
                                    </div>
                                    <ul className="d-flex align-items-center sub_btm_det">
                                        <li>
                                            {(this.state.status == 'Active') ?
                                                <a className="cursor-pointer font_16 text_black let_spa_2 fw_ar_reg text_underline" onClick={this.onHoldSubscription}>On-hold</a>
                                                : ''
                                            }
                                        </li>
                                        <li>
                                            <NavLink className="font_16 text_black let_spa_2 fw_ar_reg text_underline" to={`/my-account/change-subscription-address/${this.state.subscription_id}`}>Change Address</NavLink>
                                        </li>
                                        <li>
                                            <a onClick={this.openChangeDate} className="cursor-pointer font_16 text_black let_spa_2 fw_ar_reg text_underline">Change Payment Date</a>
                                        </li>
                                        <li>
                                            {
                                                (this.state.subscription_type == 'supplement') ?
                                                    (this.state.payment_method == 'paypal') ? (
                                                        (this.state.tenDaysDifference == true) ? (
                                                            <NavLink className="font_16 text_black let_spa_2 fw_ar_reg text_underline" to={`/subscription-item/${this.state.subscription_id}`}>Add Item</NavLink>
                                                        ): ''
                                                    ) : (
                                                        <NavLink className="font_16 text_black let_spa_2 fw_ar_reg text_underline" to={`/subscription-item/${this.state.subscription_id}`}>Add Item</NavLink>
                                                    )
                                                :
                                                <NavLink className="font_16 text_black let_spa_2 fw_ar_reg text_underline" to={`/meals?subscription_id=${this.state.subscription_id}`}>Change Meal Item</NavLink>
                                            }
                                        </li>
                                    </ul>
                                </div>
                                <div class="modal subscription_end_date_edit_calender" tabindex="-1" role="dialog">
                                    <div class="modal-dialog" role="document">
                                        <div class="modal-content">
                                            <div class="modal-header">
                                                <h5 class="font_16 text_black fw_ar_bold m-0" id="exampleModalLabel">Change Payment Date</h5>
                                                <button type="button" class="close text_black fw_ar_bold" onClick={this.closeChangeDate}>
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                            </div>
                                            <div class="modal-body">
                                                <div className=" form_input_blk ">
                                                    <div className="row">
                                                        <div className="col-md-12">
                                                        <input onChange={this.changeHandler} className="form-control w-100 datepicker" id="the_date" type="text" name="" value={moment(this.state.next_payment_date, "MMMM DD, YYYY").format('YYYY-MM-DD')} />
                                                        </div>
                                                        <div className="col-md-12 mt_23 d-flex align-items-center">
                                                        <a onClick={this.saveChange} className="cursor-pointer add_new_btn d-flex font_16 text_white fw_ar_reg">Save Change</a>
                                                        </div>
                                                    </div>
                                                    {this.state.payment_method == 'paypal' && (
                                                        <p className="text-danger">Next payment date must be at least 10 days from today.</p>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <Row>
                                <Col md={6}>
                                    <div className="auto_reniew_blk white_bg h-100">
                                        {/* <div className="enable_renew_div mb_8 align-items-center d-flex justify-content-between">
                                            <h4 className="m-0 font_16 text_black fw_ar_bold let_spa_2">Enable Auto Renew</h4>
                                            <label htmlFor="" className="switch_label m-0 position-relative">
                                                <input type="checkbox" name="" id="" />
                                                <span className="d-block switch_span"></span>
                                            </label>
                                        </div>
                                        <span className="d-block font_16 text_black let_spa_2 mb_32">This option is checked, will renew your subscription, if the current plan expires. However, this might prevent you from</span> */}
                                        <div className="order_acc_body p-0 mb_23">
                                            {
                                                this.state.items.length <= 0 ? <tr><td className="text-center" colSpan="3">No Item Found!</td></tr> :
                                                    this.state.items.map(function (item, key) {
                                                        if ((this.state.status === "Cancelled") || (this.state.status === "Failed")) {
                                                            return (
                                                                <div className="order_pro_detail d-flex align-items-center justify-content-between" key={key}>
                                                                    <div className="left_pro_de d-flex align-items-center w-50">
                                                                        <img src={productImage} alt="productImage" className='product_image' />
                                                                        <div className="pl_16">
                                                                            <h5 className="font_14 mb_6 text_black fw_el_bold let_spa_2">{item.hasOwnProperty('name') ? item.name : ''}</h5>
                                                                            {/* <span className="font_12 items_text d-block fw_ar_reg">Monthly</span> */}
                                                                            <a data-item={item.hasOwnProperty('item_id') ? item.item_id : ''} className="font_12 red_text d-block fw_ar_reg cursor-pointer">Remove</a>
                                                                        </div>
                                                                    </div>
                                                                    <div className="right_pro d-flex flex-column align-items-end">
                                                                        <h5 className="font_14 text_black let_spa_2 fw_ar_bold m-0">{item.hasOwnProperty('total_price') ? CURRENCY_FORMAT(item.total_price) : ''}</h5>
                                                                    </div>
                                                                </div>
                                                            )
                                                        } else {
                                                            return (
                                                                <div className="order_pro_detail d-flex align-items-center justify-content-between" key={key}>
                                                                    <div className="left_pro_de d-flex align-items-center w-50">
                                                                        <img src={productImage} alt="productImage" className='product_image' />
                                                                        <div className="pl_16">
                                                                            <h5 className="font_14 mb_6 text_black fw_el_bold let_spa_2">{item.hasOwnProperty('name') ? item.name : ''}</h5>
                                                                            {/* <span className="font_12 items_text d-block fw_ar_reg">Monthly</span> */}
                                                                            {
                                                                                (this.state.subscription_type == 'meal') ? '' :
                                                                                    (this.state.payment_method === 'paypal') ? (
                                                                                        (this.state.tenDaysDifference == true) ? (
                                                                                            <a data-item={item.hasOwnProperty('item_id') ? item.item_id : ''} onClick={() => this.removeSubscriptionItem(item.item_id)} className="font_12 red_text d-block fw_ar_reg cursor-pointer">Remove</a>
                                                                                        ):  ''
                                                                                    ): 
                                                                                <a data-item={item.hasOwnProperty('item_id') ? item.item_id : ''} onClick={() => this.removeSubscriptionItem(item.item_id)} className="font_12 red_text d-block fw_ar_reg cursor-pointer">Remove</a>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    <div className="right_pro d-flex flex-column align-items-end">
                                                                        <h5 className="font_14 text_black let_spa_2 fw_ar_bold m-0">{item.hasOwnProperty('total_price') ? CURRENCY_FORMAT(item.total_price) : ''}</h5>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }

                                                    }.bind(this))
                                            }
                                        </div>
                                        <div className="order_det_total">
                                            <ul className="pro_cart_det">
                                                <li className="d-flex align-items-center flex-wrap mb_7">
                                                    <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Sub Total</span>
                                                    <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.subtotal)}</span>
                                                </li>
                                                {
                                                    (this.state.discounts && this.state.discounts.length > 0) ?
                                                        this.state.discounts.map(function (discount, key) {
                                                            return (
                                                                <Fragment key={key}>
                                                                    <li className="d-flex align-items-center flex-wrap mb_7">
                                                                        <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">{discount.label}</span>
                                                                        <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(discount.amount)}</span>
                                                                    </li>
                                                                </Fragment>
                                                            )
                                                        }.bind(this))
                                                        : null
                                                }
                                                <li className="d-flex align-items-center flex-wrap mb_7">
                                                    <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Shipping</span>
                                                    <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.shipping_charge)}</span>
                                                </li>
                                                {
                                                    (this.state.taxStatus == 1) ?
                                                        <Fragment>
                                                            <li className="d-flex align-items-center flex-wrap mb_7">
                                                                <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Tax</span>
                                                                <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.tax_amount)}</span>
                                                            </li>
                                                        </Fragment>
                                                        : null
                                                }
                                                <li className="d-flex align-items-center flex-wrap">
                                                    <span className="total_span let_spa_2 d-block font_14 fw_ar_reg">Total</span>
                                                    <span className="price_span let_spa_2 text-right d-block font_14 fw_ar_reg">{CURRENCY_FORMAT(this.state.total)}</span>
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="billing_blk sub_billing_blk white_bg mb_25">
                                        <div className="w-50">
                                            <h4 className="font_16 let_spa_2 text_black mb_8 fw_ar_bold">Billing address</h4>
                                            <p className="bil_para font_16 text_black let_spa_2 mb_8 fw_ar_reg">{this.state.billing_address.hasOwnProperty('name') ? this.state.billing_address.name : ''} &nbsp;
                                                {this.state.billing_address.hasOwnProperty('street_address') ? this.state.billing_address.street_address : ''} &nbsp;
                                                {this.state.billing_address.hasOwnProperty('city') ? this.state.billing_address.city : ''} &nbsp;
                                                {this.state.billing_address.hasOwnProperty('state') ? this.state.billing_address.state : ''} &nbsp;
                                                {this.state.billing_address.hasOwnProperty('zip') ? this.state.billing_address.zip : ''}</p>
                                            <div className="d-flex align-items-center mb_8">
                                                <img src={PhoneIcon} alt="email_icon" />
                                                <span className="font_16 text_black d-block pl_10 fw_ar_reg">{this.state.billing_address.hasOwnProperty('phone') ? this.state.billing_address.phone : ''}</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <img src={EmailIcon} alt="phone_icon" />
                                                <span className="font_16 text_black d-block pl_10 fw_ar_reg">{this.state.billing_address.hasOwnProperty('email') ? this.state.billing_address.email : ''}</span>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="billing_blk sub_billing_blk white_bg">
                                        <div className="w-50">
                                            <h4 className="font_16 let_spa_2 text_black mb_8 fw_ar_bold">Shipping address</h4>
                                            <p className="bil_para font_16 text_black let_spa_2 mb_8 fw_ar_reg">{this.state.shipping_address.hasOwnProperty('name') ? this.state.shipping_address.name : ''}&nbsp;
                                                {this.state.shipping_address.hasOwnProperty('street_address') ? this.state.shipping_address.street_address : ''}&nbsp;
                                                {this.state.shipping_address.hasOwnProperty('city') ? this.state.shipping_address.city : ''}&nbsp;
                                                {this.state.shipping_address.hasOwnProperty('state') ? this.state.shipping_address.state : ''}&nbsp;
                                                {this.state.shipping_address.hasOwnProperty('zip') ? this.state.shipping_address.zip : ''}</p>
                                            {/* <div className="d-flex align-items-center mb_8">
                                                <img src={PhoneIcon} alt="email_icon" />
                                                <span className="font_16 text_black d-block pl_10 fw_ar_reg">120645122599</span>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <img src={EmailIcon} alt="phone_icon" />
                                                <span className="font_16 text_black d-block pl_10 fw_ar_reg">suman@baryons.net</span>
                                            </div> */}
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                }
            </Fragment>
        );
    }
}

export default RelatedSubscription;
