import React, { Component, Fragment } from 'react';
import { NavLink } from 'react-router-dom';
import { AJAX_REQUEST, CURRENCY_FORMAT, GET_STORAGE } from "../../../Constants/AppConstants";
import history from '../../../history';
import AlertWrapper from '../../Common/AlertWrapper';
import AlertWrapperSuccess from '../../Common/AlertWrapperSuccess';
import moment from "moment";
import $ from 'jquery';

class SubscriptionActivation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            loading: true,
            subscription_id: parseInt(this.props.match.params.subscription_id),
            success_alert_wrapper_show: false,
            error_alert_wrapper_show: true,
            server_message: '',
            submitButton: false,
            next_payment_date: '',
            submitActiveButton: false,
            isPastDate: false

        }
        document.title = "Subscription Activation -Prestige Labs";
    }

    componentDidMount() {
        document.querySelector("body").scrollIntoView() 
        this.setState({ loading: false });
        this.getSubscriptionDetails();
    }

    timeOut = (timedata) => {
        setTimeout(function () {
            this.setState({
                error_alert_wrapper_show: true,
                success_alert_wrapper_show: false
            });
            history.push('/my-account/related-subscription/'+this.state.subscription_id);
        }.bind(this), timedata);
    }

    openChangeDate = (e) => {
        $('.subscription_end_date_edit').hide();
        $('.subscription_end_date_edit_calender').show();

        $('.datepicker').daterangepicker({
            singleDatePicker: true,
            showDropdowns: true,
            startDate: moment(),
            minDate: this.state.payment_method == 'paypal' ? moment().add(10, "days") : moment(),
            locale: {
                format: 'YYYY-MM-DD'
            }
        });
    }

    closeChangeDate = (e) => {
        $('.subscription_end_date_edit').show();
        $('.subscription_end_date_edit_calender').hide();
    }

    saveChange = (e) => {
        this.setState({ submitButton: true })
        e.preventDefault();
        $('.subscription_end_date_edit').show();
        $('.subscription_end_date_edit_calender').hide();

        const next_payment_date = $('#the_date').val();
        const data = { next_payment_date: next_payment_date, subscription_id: this.state.subscription_id }

        AJAX_REQUEST("POST", "subscription/changePaymentDate", data).then(results => {
            const response = results.response;
            if (parseInt(results.response.code) === 1000) {
                const new_dated = results.response.data.next_payment_date;
                $('#show_new_date').text(new_dated);

                this.setState({
                    server_message: results.response.message,
                    success_alert_wrapper_show: true,
                    error_alert_wrapper_show: true,
                    submitButton: false,
                    next_payment_date: new_dated,
                })
               this.getSubscriptionDetails();
            } else {
                this.setState({
                    server_message: results.response.message,
                    success_alert_wrapper_show: false,
                    error_alert_wrapper_show: false,
                    submitButton: false,
                });
            }
        });
    }

     activateSubscription = (e) => {
        this.setState({ submitActiveButton: true, });
            document.querySelector("body").scrollIntoView()
            let data = {
                subscription_id: this.state.subscription_id,
            }
            AJAX_REQUEST("POST", "subscription/activate", data).then(results => {
                if (parseInt(results.response.code) === 1000) {
                    this.setState({
                        server_message: results.response.message,
                        success_alert_wrapper_show: true,
                        error_alert_wrapper_show: true,
                        submitActiveButton: false,
                    });
                    this.timeOut(3000);
                } else{
                    this.setState({
                        server_message: results.response.message,
                        success_alert_wrapper_show: false,
                        error_alert_wrapper_show: false,
                        submitActiveButton: false,
                    });
                }
            });
        }

    getSubscriptionDetails = () => {
        let data = {
            subscription_id: this.state.subscription_id
        }
        AJAX_REQUEST("POST", "subscription/getDetails", data).then(results => {
            if (parseInt(results.response.code) === 1000) {
                const isPastDate = moment(results.response.data.next_payment_date).isBefore(moment(), 'day');
                this.setState({
                    next_payment_date: results.response.data.next_payment_date,
                    payment_method: results.response.data.subscription.payment_method,
                    loading: false,
                    isPastDate: isPastDate
                });
            } else {
                this.setState({
                    error_meg: results.response.message,
                    loading: false,
                });
            }
        });
    }

    render() {

        return (
            
            <Fragment>
                 <AlertWrapper errors_data={this.state.server_message} isFormValid={this.state.error_alert_wrapper_show} />
                <AlertWrapperSuccess errors_data={this.state.server_message} success_alert_wrapper_show={this.state.success_alert_wrapper_show} />
                {
                    
                    this.state.loading ?
                        <div className="loading"></div>
                        :
                        <div className="MyAccount-content">
                            <section className="woocommerce-order-details">
                                <div className="mb_15 d-flex align-items-center justify-content-between">
                                    <h2 className="font_26 m-0 text_black let_spa_2 fw_el_bold">SUBSCRIPTION ACTIVATION</h2>
                                  
                                </div>
                                <div className='sub_billing_blk white_bg'>
                                    <Fragment>
                                        <p className='font_16 text_black fw_ar_reg mb_30'>
                                            Do you want to change your next delivery?
                                            We can help with that by clicking the corresponding button below.
                                        </p>
                                            <div className="subscription_end_date_edit float-none text-left">
                                                <a onClick={this.openChangeDate} className="cursor-pointer  font_16 text_black text_underline">Change Payment Date</a>
                                            </div>
                                            <div className="subscription_end_date_edit_calender form_input_blk float-none">
                                                <div className="col-md-6 p-0">
                                                    <div className="form-group">
                                                        <input onChange={this.changeHandler} className="form-control datepicker" id="the_date" type="text" name="" />
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <a onClick={this.saveChange} className="cursor-pointer add_new_btn d-flex font_16 text_white fw_ar_reg">{this.state.submitButton ? "Saving..." : "Save Change"}</a> &nbsp;
                                                        <a onClick={this.closeChangeDate} className="ml_15 font_16 text_black fw_ar_reg text_underline cursor-pointer">Cancel</a>
                                                    </div>
                                                </div>
                                                {this.state.payment_method == 'paypal' && (
                                                    <p className="text-danger">Next payment date must be at least 10 days from today.</p>
                                                )}
                                            </div>
                                       
                                    </Fragment>
                                
                                    {this.state.isPastDate ? (
                                        <div className="alert alert-danger font_16 text_black fw_ar_reg mb_20 alertdate">
                                            You cannot activate your subscription as the next payment date <strong>({this.state.next_payment_date})</strong> is in the past. Please update your payment date first.
                                        </div>
                                    ) : (
                                        <div>
                                            <p className='font_16 text_black fw_ar_reg mb_20 textact'>
                                                You can proceed with activating your subscription, and your next payment will be scheduled for <strong>{this.state.next_payment_date}</strong>.
                                            </p>
                                            <div className="d-flex align-items-center activate">
                                                <a onClick={this.activateSubscription} 
                                                className={`cursor-pointer add_new_btn d-flex font_16 text_white fw_ar_reg ${this.state.submitActiveButton ? 'disabled' : ''}`} 
                                                style={{ pointerEvents: this.state.submitActiveButton ? 'none' : 'auto' }}>
                                                {this.state.submitActiveButton ? "Please Wait..." : "ACTIVATE SUBSCRIPTION"}
                                                </a> &nbsp;
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </section>
                        </div>
                }
            </Fragment>
        );
    }
}

export default SubscriptionActivation;
