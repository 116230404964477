import React, { Component, Fragment } from 'react';
import { AJAX_PUBLIC_REQUEST, AJAX_REQUEST, REFER_URL, PUBLIC_URL, CUSTOMER_URL, DISTRIBUTOR_URL, GET_STORAGE, BLOG_URL } from '../../../Constants/AppConstants';
import { NavLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MenuDropdown from './../../../Assets/images/customer_images/menu_dropdown.svg'
import { Row, Col } from 'react-bootstrap';
import BestSeller from '../../../Assets/images/customer_images/best_sellers_img.svg'
import Basecollect from '../../../Assets/images/customer_images/base_collection_img.svg'
import BoostCollect from '../../../Assets/images/customer_images/boost_collection_img.svg'
import Bundles from '../../../Assets/images/customer_images/bundles_icon.svg'
import BalanceCollect from '../../../Assets/images/customer_images/balance_col_img.svg'
import Protein from '../../../Assets/images/customer_images/protein_img.svg'
import Workout from '../../../Assets/images/customer_images/pro_workout_img.svg'
import WeightLoss from '../../../Assets/images/customer_images/weight_loss_img.svg'
import BundleMuscle from '../../../Assets/images/customer_images/build_muscle_img.svg'
import EveryDayHealth from '../../../Assets/images/customer_images/everyday_helth_img.svg'
import QuestionImg from '../../../Assets/images/customer_images/question_img.svg'
import Chart3d from '../../../Assets/images/customer_images/chart_3d_img.svg'
import ContactImg from '../../../Assets/images/customer_images/contactus_img.svg'
import FaqImg from '../../../Assets/images/customer_images/faq_img.svg'
import AffLab from '../../../Assets/images/customer_images/aff_lab.svg'

class CustDistMenu extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() {
        const { user } = this.props.auth;

        let settings = null;
        if (GET_STORAGE("settings")) {
            settings = JSON.parse(GET_STORAGE("settings"));
        }

        let meal_menu_active = false;
        let enable_new_signup = false;
        if (settings && settings.enable_new_signup == "yes") {
            enable_new_signup = true;
        }
        if (settings && settings.meal_menu_public == "yes") {
            meal_menu_active = true;
        } else {
            if (this.props) {
                if (this.props.auth) {
                    if (this.props.auth.user) {
                        if (this.props.auth.user.meal_menu_activated) {
                            meal_menu_active = true;
                        }
                    }
                }
            }
        }

        return (
            <Fragment>
                <li key={Math.random()}><a className="menu_item" href={PUBLIC_URL}>HOME</a></li>
                <li key={Math.random()}><a className="menu_item" href={PUBLIC_URL + 'products'}>PRODUCTS</a></li>
                {/* <li key={Math.random()}><a className="menu_item" href={PUBLIC_URL + 'athletes'}>ATHLETES</a></li> */}
                <li key={Math.random()}><a className="menu_item" href={PUBLIC_URL + 'page/our-story'}>OUR STORY</a></li>
                <li key={Math.random()}><a className="menu_item" href={PUBLIC_URL + 'contact'}>CONTACT</a></li>
                <li key={Math.random()}><a className="menu_item" href={PUBLIC_URL + 'page/faq-info'}>FAQ</a></li>
                <li key={Math.random()}><a className="menu_item" href={PUBLIC_URL}>SHOP</a></li>
                <li key={Math.random()}><a className="menu_item" href={ BLOG_URL }>BLOG</a></li>
                {/* {
                    user.site ?
                        <Fragment>
                            {
                                user.site === 'refer' ?
                                    <li key={Math.random()}><a className="menu_item d-flex align-items-center" href={REFER_URL}><span>SHOP</span></a></li>
                                    : <li key={Math.random()}><a className="menu_item d-flex align-items-center" href={PUBLIC_URL}><span>SHOP</span></a></li>
                            }
                        </Fragment>
                        :
                        ''
                } */}
                {/* Shop HTML */}
                {/* <li class="dropdown position-static header_sub_menu">
                    <a id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="menu_item d-flex align-items-center" href="#"><span>SHOP</span><img src={MenuDropdown} alt="dropDown" /></a>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                        <div className="container">
                            <Row className="shop_menu align-items-center">
                                <Col md={10}>
                                    <Row className="shop_menurow">
                                        <Col className="w-20">
                                            <a href={`${REFER_URL}top-sellers`} className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                <img src={require("../../../Assets/images/refer_images/efa_img.png")} alt="best seller" />
                                                <span className="font_16 p-0 text-center d-block text_black mt_17 fw_el_bold">Best Sellers</span>
                                            </a>
                                        </Col>
                                        <Col className="w-20">
                                            <a href={`${REFER_URL}bundle-product`} className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                <img src={Bundles} alt="bundles" />
                                                <span className="font_16 p-0 text-center d-block text_black mt_17 fw_el_bold">Bundles</span>
                                            </a>
                                        </Col>
                                <Col className="w-20">
                                            <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                <img src={Basecollect} alt="Base Collection" />
                                                <span className="font_16 p-0 text-center d-block text_black mt_17 fw_el_bold">Base Collection</span>
                                            </a>
                                        </Col>
                                        <Col className="w-20">
                                            <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                <img src={BoostCollect} alt="Boost Collection" />
                                                <span className="font_16 p-0 text-center d-block text_black mt_17 fw_el_bold">Boost Collection</span>
                                            </a>
                                        </Col>
                                        <Col className="w-20">
                                            <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                <img src={BalanceCollect} alt="Balance Collection" />
                                                <span className="font_16 p-0 text-center d-block text_black mt_17 fw_el_bold">Balance Collection</span>
                                            </a>
                                        </Col>
                                        <Col className="w-20">
                                            <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                <img src={Protein} alt="Protein" />
                                                <span className="font_16 p-0 text-center d-block text_black mt_17 fw_el_bold">Protein</span>
                                            </a>
                                        </Col>
                                        <Col className="w-20">
                                            <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                <img src={Workout} alt="Pre/Intra Workout" />
                                                <span className="font_16 p-0 text-center d-block text_black mt_17 fw_el_bold">Pre/Intra Workout</span>
                                            </a>
                                        </Col>
                                        <Col className="w-20">
                                            <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                <img src={WeightLoss} alt="Weight Loss" />
                                                <span className="font_16 p-0 text-center d-block text_black mt_17 fw_el_bold">Weight Loss</span>
                                            </a>
                                        </Col>
                                        <Col className="w-20">
                                            <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                <img src={BundleMuscle} alt="Build Muscle" />
                                                <span className="font_16 p-0 text-center d-block text_black mt_17 fw_el_bold">Build Muscle</span>
                                            </a>
                                        </Col>
                                        <Col className="w-20">
                                            <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                <img src={EveryDayHealth} alt="Everyday Health" />
                                                <span className="font_16 p-0 text-center d-block text_black mt_17 fw_el_bold">Everyday Health</span>
                                            </a>
                                        </Col> 
                               </Row>
                                </Col>
                                <Col md={12} className="d-flex align-items-center justify-content-center">
                                    <a href={`${REFER_URL}products`} className="font_16 text_black fw_el_bold d-flex shop_all_link let_spa_3">Shop All</a>
                                </Col>
                                <Col md={12} className="d-flex align-items-center justify-content-center">
                                    <a href={`${REFER_URL}top-sellers`} className="font_16 text_black fw_el_bold d-flex shop_all_link let_spa_3">Best Sellers</a>
                                </Col>
                                <Col md={12} className="d-flex align-items-center justify-content-center">
                                    <a href={`${REFER_URL}bundle-product`} className="font_16 text_black fw_el_bold d-flex shop_all_link let_spa_3">Bundles</a>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </li> */}
                {/* Shop HTML Ends */}

                {/* Get Started HTML */}
                {/* <li class="dropdown position-static header_sub_menu">
                    <a id="getSytarted" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="menu_item d-flex align-items-center" href="#"><span>GET STARTED</span><img src={MenuDropdown} alt="dropDown" /></a>
                    <div class="dropdown-menu" aria-labelledby="getSytarted">
                        <div className="container">
                            <Row className="shop_menu align-items-center">
                                <Col md={12}>
                                    <Row className="shop_menurow justify-content-center">
                                        <Col className="w-20">
                                            <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                <img src={QuestionImg} alt="Take the Quiz" />
                                                <span className="font_16 p-0 text-center d-block text_black mt_17 fw_el_bold">Take the Quiz</span>
                                            </a>
                                        </Col>
                                        <Col className="w-20">
                                            <a href={`${REFER_URL}bundle-product`} className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                <img src={Bundles} alt="bundles" />
                                                <span className="font_16 p-0 text-center d-block text_black mt_17 fw_el_bold">Bundles</span>
                                            </a>
                                        </Col>
                                        <Col className="w-20">
                                            <a href="#" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                <img src={Chart3d} alt="chart 3d" />
                                                <span className="font_16 p-0 text-center d-block text_black mt_17 fw_el_bold">Chat with Prestige Labs Advisor</span>
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </li> */}
                {/* Get Started HTML Ends */}
                {/* <li><a className="menu_item" href='#'>TESTIMONIALS</a></li> */}
                {/* <li><a className="menu_item" href='/page/our-story'>ABOUT US</a></li> */}
                {/* <li><a className="menu_item" href='https://shop.prestigelabs.com/blogs/events' target="_blank">BLOG</a></li> */}
                {/* Support HTML */}
                {/* <li class="dropdown position-static header_sub_menu">
                    <a id="getSytarted" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" className="menu_item d-flex align-items-center" href="#"><span>Support</span><img src={MenuDropdown} alt="dropDown" /></a>
                    <div class="dropdown-menu" aria-labelledby="getSytarted">
                        <div className="container">
                            <Row className="shop_menu align-items-center">
                                <Col md={12} className="d-flex align-items-center justify-content-center">
                                    <a href="/contact" className="font_16 text_black fw_el_bold d-flex shop_all_link let_spa_3">Contact</a>
                                </Col>
                                <Col md={12} className="d-flex align-items-center justify-content-center">
                                    <a href="/page/faq-info" className="font_16 text_black fw_el_bold d-flex shop_all_link let_spa_3">FAQs</a>
                                </Col>
                                <Col md={12} className="d-flex align-items-center justify-content-center">
                                    <a href="https://affiliate.prestigelabs.com/login" className="font_16 text_black fw_el_bold d-flex shop_all_link let_spa_3">Become a Prestige Labs Affiliate</a>
                                </Col>
                                <Col md={12}>
                                    <Row className="shop_menurow justify-content-center">
                                        <Col className="w-20">
                                            <a href="/contact" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                <img src={require("../../../Assets/images/customer_images/Contact_Us.png")} alt="contact img" />
                                                <span className="font_16 p-0 text-center d-block text_black mt_17 fw_el_bold">Contact Us</span>
                                            </a>
                                        </Col>
                                        <Col className="w-20">
                                            <a href="/page/faq-info" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                <img src={require("../../../Assets/images/customer_images/FAQ.png")} alt="faq" />
                                                <span className="font_16 p-0 text-center d-block text_black mt_17 fw_el_bold">FAQ’s</span>
                                            </a>
                                        </Col>
                                        <Col className="w-20">
                                            <a href="https://affiliate.prestigelabs.com/login" target="_blank" className="shop_menu_li h-100 d-flex align-items-center justify-content-center flex-column">
                                                <img src={require('../../../Assets/images/customer_images/Become_an_Affiliate.png')} alt="aff lab img" />
                                                <span className="font_16 p-0 text-center d-block text_black mt_17 fw_el_bold">Become a Prestige Labs Affiliate</span>
                                            </a>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>
                    </div>
                </li> */}
                {/* Support HTML Ends */}
                {/* {
                    (this.props.menus.length <= 0) ? null :
                        this.props.menus.map(function (menu, key) {

                            if ((user.roles != undefined) && Object.values(user.roles).includes('master_affiliate') && !Object.values(user.roles).includes('distributor')) {
                                if ((menu.url === '') || (menu.url === '/') || (menu.url === '/meals')) {

                                } else {
                                    if (menu.type === "external") {
                                        if(menu.manual_position != 'last'){
                                            return (
                                                <li key={'ddm' + key}><a target={menu.open_new_tab== "yes"?"_blank":"_self"} className="menu_item" href={menu.url}>{menu.label}</a></li>
                                            );
                                        }
                                    } else {
                                        if (menu.url === "/my-account") {
                                            console.log('if',menu.slug2)
                                            return (
                                                <li key={'ddm' + key}><a className="menu_item" href={DISTRIBUTOR_URL+menu.slug2}>{enable_new_signup?'Master Affiliate Dashboard':'Master Affiliate Panel'}</a></li>)
                                        } else {
                                            console.log('else',menu.slug2)
                                            return (
                                            <li key={'ddm' + key}><a className="menu_item" href={DISTRIBUTOR_URL+menu.slug2}>{menu.label}</a></li>)
                                        }
                                    }
                                }
                            } else {
                                if (menu.type === "external") {
                                    if(!menu.url == 'https://gymlaunch.com/shop/'){
                                        return (
                                            <li key={'ddm' + key}><a target={menu.open_new_tab== "yes"?"_blank":"_self"} className="menu_item" href={menu.url}>{menu.label}</a></li>
                                        );
                                    }
                                } else {
                                    if (menu.url === "/my-account") {
                                        if ((user.roles != undefined) && Object.values(user.roles).includes('distributor')) {
                                            return (
                                            <li key={'ddm' + key}><a className="menu_item" href={DISTRIBUTOR_URL+menu.slug2}>{enable_new_signup?'Affiliate Dashboard':'Affiliate Panel'}</a></li>)
                                        } else {
                                            return (<li key={'ddm' + key}><a className="menu_item" href={DISTRIBUTOR_URL+menu.slug2}>Team Member Panel</a></li>)
                                        }
                                    } else {
                                        if(menu.url === "/meals"){
                                            if(meal_menu_active){
                                                return (<li key={'ddm' + key}><a className="menu_item" href={DISTRIBUTOR_URL+menu.slug2}>{menu.label}</a></li>)
                                            }else{
                                                return null
                                            }
                                        }else{
                                            return (<li key={'ddm' + key}><a className="menu_item" href={DISTRIBUTOR_URL+menu.slug2}>{menu.label}</a></li>)
                                        }
                                    }
                                }
                            }

                        })
                }

                {
                    (this.props.auth.isAuthenticated && Object.values(user.roles).includes('distributor') && Object.values(user.roles).includes('master_affiliate')) ?
                        <li key={Math.random()}><a className="menu_item" href={DISTRIBUTOR_URL+'my-affiliate-account'}>{enable_new_signup?'Master Affiliate Dashboard':'Master Affiliate Panel'}</a></li>
                        : ''
                }

                {
                    (this.props.auth.isAuthenticated && Object.values(user.roles).includes('customer')) ?
                    <li key={Math.random()}><NavLink activeClassName="active" className="menu_item" to="/my-account" exact>{enable_new_signup?'My Orders':'My Account'}</NavLink></li>
                        : ''
                }

                {
                    (this.props.auth.isAuthenticated && (Object.values(user.roles).includes('master_affiliate') || Object.values(user.roles).includes('distributor'))) ?
                        <li key={Math.random()}><a className="menu_item" href="https://prestigelabs.mykajabi.com/" target='_blank'>Training</a></li>
                        : ''
                }

                {
                    (this.props.auth.isAuthenticated && Object.values(user.roles).includes('team_member')) ?
                        <li key={Math.random()}><a className="menu_item" href="https://prestigelabs.mykajabi.com/" target='_blank'>Training</a></li>
                        : ''
                }

               

                {
                    (this.props.menus.length <= 0) ? null :
                    this.props.menus.map(function (menu, key) {
                        if (menu.type === "external") {
                            if(menu.manual_position == 'last'){
                                return (
                                    <li key={'ddm' + key}><a target={menu.open_new_tab== "yes"?"_blank":"_self"} className="menu_item" href={menu.url}>{menu.label}</a></li>
                                );
                            }
                        }
                    })
                } */}
            </Fragment>
        );
    }
}

CustDistMenu.propTypes = {
    auth: PropTypes.object.isRequired
}

function mapStateToProps(state) {
    return {
        auth: state.auth
    };
}

export default connect(mapStateToProps)(CustDistMenu);
